<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Kategorie</label>
          <input
            type="text"
            v-model="document.categorie"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Name</label>
          <input
            type="text"
            v-model="document.name"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.document.name.$error }"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Link</label>
          <input
            type="text"
            v-model="document.link"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.document.link.$error }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { createOne, updateOne } from '@/services/document'

const tempDocument = {
  categorie: '',
  name: '',
  link: '',
}

export default {
  name: 'new-document',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      document: { ...tempDocument },
    }
  },
  validations(){
    return {
      document:{
        name:{
          required,
        },
        link:{
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.document = { ...this.payload }
    },
    save() {
      this.$v.document.$touch()

      if (this.$v.$invalid){
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
      this.document['roleprofile'] = this.parent_id
      if (this.document.id) {
        updateOne(this.document).then(() => {
          this.$emit('close-modal', { show: false })
        })
      } else {
        createOne(this.document).then(() => {
          this.$emit('close-modal', { show: false })
        })
      }
      }
    },
  },
  watch: {},
}
</script>
